<template>
  <div id="gaode-map" :style="`height:${height}`">
    <div id="gaodeMap"></div>
    <input id="keywordSearch" placeholder="地区关键字" />
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: "200px",
    },
    //初始化的经纬度
    initCoord: {
      type: Object | null,
      default: null,
    },
  },
  watch: {
    initCoord: {
      handler() {
        this.initMap();
      },
      deep: true,
    },
  },
  data() {
    return {
      map: null,
      //经度
      longitude: "",
      //纬度
      latitude: "",
      //地址
      address: "",
      //地址完整信息
      addressComponent: "",
      //标记点
      marker: null,
    };
  },
  created() {
    this.initMap();
  },
  methods: {
    reset() {
      this.map && this.map.destroy();
      this.map = null;
      this.longitude = "";
      this.latitude = "";
      this.address = "";
      this.addressComponent = "";
      this.marker = null;

      const keywordSearch = document.getElementById("keywordSearch");
      keywordSearch.value = "";
    },
    addMarker(longitude, latitude) {
      if (this.marker) {
        this.map.remove(this.marker);
      }
      this.marker = new AMap.Marker({
        position: new AMap.LngLat(longitude, latitude),
      });
      this.map.add(this.marker);
    },
    initMap() {
      this.$nextTick(() => {
        let _this = this;
        let center = null;
        if (this.initCoord?.lng && this.initCoord?.lat) {
          center = [this.initCoord.lng, this.initCoord.lat];
        }
        this.map = new AMap.Map("gaodeMap", {
          center,
          zoom: 12,
        });
        if (this.initCoord?.lng && this.initCoord?.lat) {
          this.addMarker(this.initCoord.lng, this.initCoord.lat);
        }
        this.map.on("click", (ev) => {
          this.longitude = ev.lnglat.lng;
          this.latitude = ev.lnglat.lat;
          this.getRegionInfo(ev.lnglat.lng, ev.lnglat.lat);
          this.addMarker(ev.lnglat.lng, ev.lnglat.lat);
        });

        //输入框事件
        AMapUI.loadUI(["misc/PoiPicker"], function(PoiPicker) {
          let poiPicker = new PoiPicker({
            input: "keywordSearch", //输入框id
          });
          //监听poi选中信息
          poiPicker.on("poiPicked", function(poiResult) {
            if (poiResult.item.location) {
              let longitude = poiResult.item.location.lng;
              let latitude = poiResult.item.location.lat;
              _this.map.panTo([longitude, latitude]);
              _this.addMarker(longitude, latitude);
              _this.getRegionInfo(longitude, latitude);
            } else {
              _this.$message.error("未查询到该地址");
            }
          });
        });
      });
    },
    getRegionInfo(lng, lat) {
      //获取省市区街道等信息
      let geocoder = new AMap.Geocoder({
        radius: 1000,
        extensions: "all",
      });
      geocoder.getAddress([lng, lat], (status, result) => {
        if (status === "complete" && result.info === "OK") {
          this.address = result.regeocode.formattedAddress;
          this.addressComponent = result.regeocode.addressComponent;
          this.$emit("mapClick", {
            longitude: lng,
            latitude: lat,
            address: this.address,
            addressComponent: this.addressComponent,
          });
        }
      });
    },
    beforeDestroy() {
      this.reset();
    },
  },
};
</script>
<style lang="scss">
#gaode-map {
  width: 100%;
  max-width: 820px;
  position: relative;
  #gaodeMap {
    width: 100%;
    height: 100%;
    .amap-logo,
    .amap-copyright {
      display: none !important;
    }
  }
  #keywordSearch {
    width: 100px;
    padding: 5px 5px;
    border: 1px solid $main-color;
    border-radius: 4px;
    position: absolute;
    top: 10px;
    left: 10px;
    &:focus-visible {
      outline: 0;
    }
  }
}
</style>
